<template>
  <div>
    <p class="mb-3">Which of the molecules listed below would be considered polar?</p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question331',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking

      inputs: {
        input1: null,
      },
      options: [
        {text: '$\\ce{SO2}$', value: 'so2'},
        {text: '$\\ce{SO3}$', value: 'so3'},
        {text: '$\\ce{N2}$', value: 'n2'},
        {text: '$\\ce{[ICl2]-}$', value: 'icl2'},
        {text: '$\\ce{BCl3}$', value: 'bcl3'},
      ],
    };
  },
};
</script>
